// export const baseFacebook = (callback: any) => {
//   FB.login(
//     function (response: any) {
//       callback(response.authResponse.accessToken);
//     },
//     { scope: "public_profile,email" }
//   );
// };
//
// export const registerFacebook = () => {};
//
// export const registerGoogle = () => {};
//
// export const loginGoogle = () => {};
//
// export const loginFacebook = () => {};

export const getSocialUrls = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/settings/general`,
    method: "get",
  });
};
