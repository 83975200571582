<template>
  <div
    v-if="activeEntityWallets.length"
    class="flex flex-wrap justify-center gap-x-4 gap-y-4 sm:gap-x-8"
  >
    <template v-if="isLoading">
      <CustomSkeleton
        v-for="item in 8"
        :key="item"
        class="flex-shrink-0 select-none"
      />
    </template>
    <template v-else>
      <div
        v-for="wallet in listEntityWallets"
        :key="wallet.id"
        class="pointer-events-none flex-shrink-0 select-none"
      >
        <v-img
          loading="lazy"
          :src="wallet.coin_image"
          :alt="wallet.code"
          height="40"
          width="40"
          rounded
        />
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { useGetListEntityWallets } from "~/queries/walletService";

const { data: listEntityWallets, isLoading } = useGetListEntityWallets();
const activeEntityWallets = computed(
  () => listEntityWallets.value?.filter((item) => item.is_active) || []
);
</script>
