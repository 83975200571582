import { useQuery } from "@tanstack/vue-query";
import { getSocialUrls } from "~/service/socialService";
import { socialUrls } from "~/types/schema";

export const useGetSocialService = () => {
  return useQuery<any, Error, socialUrls>({
    queryFn: () => getSocialUrls(),
    queryKey: ["useGetSocialService"],
    select: (res) => res?.data?.data,
  });
};
