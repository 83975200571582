<template>
    <p
        class="mb-4 text-xs text-neutral-75-new"
        v-html="
          $t('footer.bottom_footer_1', {
            url: `<span class='d-inline-block first-letter:uppercase'>${url}</span>`,
          })
        "
    />
</template>
<script lang="ts" setup>
import {getDomainFromSubdomain} from "~/utils";

const url = getDomainFromSubdomain();
</script>
