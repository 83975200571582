<template>
    <div style="width: 80px">
        <div
            :id="sites[hostname]?.id"
            :data-anj-seal-id="sites[hostname]?.dataAnjSealId"
            :data-anj-image-size="sites[hostname]?.dataAnjImageSize"
            data-anj-image-type="basic-small"
        ></div>
    </div>
</template>

<script lang="ts" setup>
import {siteInfo} from "~/config/siteInfo";

const config = useRuntimeConfig();

const existHostname = isClient()
    ? siteInfo.sites[window.location.hostname]
    : false;
const hostname = existHostname ? window.location.hostname : "casinopunkz.io";
const sites = siteInfo.sites;
const dynamicAnjVarName = sites[hostname]?.var;

useHead({
    script: [
        {
            src: sites[hostname]?.src,
        },
    ],
});


onMounted(() => {
    if (typeof window[dynamicAnjVarName] !== "undefined") {
        // @ts-ignore
        window[dynamicAnjVarName].init?.();
    } else {
        console.log("The script hasn't loaded yet.");
    }

    if (!existHostname) {
        const pollInterval = 1000;
        const maxPollTime = 20;
        let elapsedTime = 0;

        const poll = setInterval(() => {
            const replaceHostname =
                config.public.MAIN_MIRROR_DOMAIN || "casinopunkz.io";

            if (document.getElementById(sites[replaceHostname]?.id)) {
                clearInterval(poll);
                updateSeal(sites[replaceHostname], replaceHostname);
            } else {
                elapsedTime += pollInterval;
                if (elapsedTime >= maxPollTime) {
                    clearInterval(poll);
                    console.error("Seal container not found within the expected time.");
                }
            }
        }, pollInterval);
    }
});

const updateSeal = (site: any, newDomain: any) => {
    const sealContainer = document.getElementById(site?.id);
    if (sealContainer) {
        const anchorTag = sealContainer.querySelector("a");
        const imgTag = sealContainer.querySelector("img");

        const currentDomain = window.location.hostname;

        if (anchorTag) {
            anchorTag.setAttribute('aria-label', 'validate')
            anchorTag.href = anchorTag.href.replace(
                new RegExp(currentDomain, "g"),
                newDomain
            );
        }
        if (imgTag) {
            imgTag.src = imgTag.src.replace(
                new RegExp(currentDomain, "g"),
                newDomain
            );
        }
    }
};
</script>
